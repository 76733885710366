/* Default background styling */
body {
    margin: 0;
    padding: 0;
    background-image: url('../public/assets/Background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Media query for smaller screens (e.g., mobile devices) */
@media only screen and (max-width: 600px) {
    body {
        background-size: contain;
        background-size:80%;
        width:100%;
        overflow-x: hidden; /* Prevent horizontal scrolling */
        
    }

    #Navbar {
        position: fixed;
        top: 0;
        width: 10vh;
        height: 12vH;
        background-color: rgba(255, 255, 255, 0.5);
        /*background-color: transparent !important;*/
        z-index: 1000; /* Ensure the navbar is above other content */
    }

    .container-fluid {
        max-width: 100%; /* Adjust container width for smaller screens */
        display: flex;
        justify-content: center;
        flex-direction: column; /* Arrange items vertically */
        align-items: center; /* Align items to the start of the cross axis (left) */
        justify-content: center; /* Center items along the main axis (center vertically) */
    
    }

    #home .custom-text {
        color: rgba(255, 255, 255, 0.5);
        margin-top: 5vh;
        font-size: 4vw; /* Responsive font size */
    }
    
    .logo-container {
        background-image: url("../public/assets/the-speech-vektor-red_1.png");
        background-repeat: no-repeat;
        background-size: 25vH;
        width: 10%; /* Adjust as needed */
        height: 10vh; /* Adjust as needed */
        background-position: center;
        filter: brightness(200%); /* Adjust the percentage as needed */
        filter: contrast(200%);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
    .card {
        display: flex;
        height: 40%;
        width: 75%;
        text-align: center;
    }

    .card .card-title{
        font-size: 4vwx;
        font-weight:bolder;
    }
    
    .card .card-text{
        font-size: 3vw;
        font-weight:bold;
    }
}

/* Home */

#Navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    /*background-color: transparent !important;*/
    z-index: 1000; /* Ensure the navbar is above other content */
  }

#navbar .transparent-navbar {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  #navbar  .intransparent-navbar {
    background-color:  rgba(255, 255, 255, 0.5); /* Set your desired background color */
  }
.logo-navbar {
    filter: brightness(200%); /* Adjust the percentage as needed */
    filter: contrast(270%);
}

.container-fluid {
    max-width: 95%; /* Limit the container-fluid to 80% of the screen width */
    margin-right: auto; /* Center the container horizontally */
    margin-left: auto; /* Center the container horizontally */
    overflow-y:hidden !important;
}


#home {
    background-clip: border-box;
    background-image: url("../public/assets/Background.jpg");
    background-origin: padding-box;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh; 
    background-attachment: fixed; /* Keeps the background fixed while scrolling */
}

#home .custom-text {
    color: rgba(255, 255, 255, 0.5);
    margin-top: 5vh;
    font-size: 4vw; /* Responsive font size */
}

#home .logo-container {
    background-image: url("../public/assets/the-speech-vektor-red_1.png");
    background-repeat: no-repeat;
    background-size: auto;
    width: 100%; /* Adjust as needed */
    height: 50vh; /* Adjust as needed */
    background-position: center;
    filter: brightness(200%); /* Adjust the percentage as needed */
    filter: contrast(200%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.Coockie {
    Top: 220%;
    color:cornsilk;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    background-color:  rgba(255, 255, 255, 0.185); /* Set your desired background color */
}

.Section2 {
    position: relative; /* Ensure the section is positioned relative for the next gigs*/
}

.Section2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/assets/Background.jpg"); /* Replace with your background image */
    background-size: cover;
    background-attachment: fixed;
    filter: grayscale(100%); /* Adjust filter effect for Next Gigs section */
    z-index: -1; /* Ensure the pseudo-element is behind other content */
}

.Section3 {
    position: relative; /* Ensure the section is positioned relative for the ABOUT US*/
    
}

.Section3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/assets/Background.jpg"); /* Replace with your background image */
    background-size: cover;
    background-attachment: fixed;
    filter: sepia(100%); /* Adjust filter effect for Next Gigs section */
    z-index: -1; /* Ensure the pseudo-element is behind other content */
}

#about .text-container {
    background-color: rgba(255, 255, 255, 0.5); /* Add a semi-transparent white background */
    /* width: 100%; /* Adjust as needed */
    /*height: 50vh; /* Adjust as needed */
    /*padding: 20px; /* Add padding for spacing */
    /*overflow: auto; /* Enable scrolling if content exceeds container */
    color: black; /* Set text color to black for readability */
    font-size: 1.75vw;
    line-height: 1.5; /* Adjust line height for readability */
    text-align: center; /* Center-align text */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8); /* Add a subtle shadow for depth */
}   

.section-container {
    /* Stile für den Abschnitt, wenn er nicht erweitert ist */
    height:100vh;
}
  
.section-container.expanded {
    height: auto; /* Höhe ändern, wenn der Abschnitt erweitert wird */
}
  
.expand-switch {
    display: block; /* Standardmäßig anzeigen */
}
  
.section-container.expanded .expand-switch {
    display: none; /* Verstecke den Schalter, wenn der Abschnitt erweitert wird */
}
  

  
.expand-switch {
    position: absolute;
    left: 41%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background*/
    color:black;
    font-size: 4vw;
    cursor: pointer;
}


.Section4 {
    position: relative; /* Ensure the section is positioned relative for the next gigs*/
}

.Section4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/assets/Background.jpg"); /* Replace with your background image */
    background-size: cover;
    background-attachment: fixed;
    filter: contrast(200%); /* Adjust filter effect for Next Gigs section */
    z-index: -1; /* Ensure the pseudo-element is behind other content */
}


.Section5 {
    position: relative; /* Ensure the section is positioned relative for the next gigs*/
}

.Section5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../public/assets/Background.jpg"); /* Replace with your background image */
    background-size: cover;
    background-attachment: fixed;
    filter: blur(5px); /* Adjust filter effect for Next Gigs section */
    z-index: -1; /* Ensure the pseudo-element is behind other content */
}


/* Cards */

.card {
    transition: all 0.54s ease-in;
    background-clip: border-box;
    background-image: url("../public/assets/Background_purple_ Samples_Tiles.png");
    background-origin: padding-box;
    background-position: center top;
    background-repeat:repeat;
    background-size:contain;
    font-size: 2vw;
    overflow: auto;
    margin: 1cqmin; 
}
.card:hover {
    cursor: pointer;
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.649);
}

.card .card-title{
    font-size: 4vwx;
    font-weight:bolder;
}

.card .card-text{
    font-size: 1.25vw;
    font-weight:bold;
}

/* Footer */

.footer {
    background: #ef9826;
    background: linear-gradient(90deg, #ef8726 20%, #f9c78f 80%);  
    /*filter: sepia(100%); Adjust filter effect for section 1 */
}

